






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { BarRuleType, BarTypePass } from '@/types/Barre'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopupDefinirLesBarresAdmission extends Vue {
    // ************* DATAS
    @Prop() showPopup?: boolean
    @Prop() concour?: any
    @Prop() canDefineBarre?: boolean

    show                        = false
    selectedConcourPhases: any  = []
    barres: any                 = []
    ready                       = false
    error                       = null
    isWaiting                   = false
    selectedStep: any           = {}
    maxBarre                    = 1

    /* Valeur des steps */
    definitionStep = [
        { step: 0.01 },
        { step: 0.10 },
        { step: 0.25 },
        { step: 0.50 },
        { step: 1 }
    ]

    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    noPrecisionNeed = true
    // ************* LISTENERS
    @Watch('showPopup')
    initPopup () {
        if (this.showPopup === true) {
            this.error = null
            this.selectedConcourPhases = this.$store.getters['definitionDesBarres/selectedConcour']

            this.barres = []

            if(this.selectedConcourPhases.deliberation && this.selectedConcourPhases.deliberation.barres){
                for(const barre of this.selectedConcourPhases.deliberation.barres) {
                    if(!this.hiddenBarres.includes(barre.type)) {
                        for(const threshold of barre.thresholds) {
                            if (this.barres.length !== 0) {
                                const check_barre = this.barres.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                                if (!check_barre) {
                                //this.barres_actives.push(threshold)
                                    this.barres.push({
                                        id: barre.id,
                                        value: threshold.value,
                                        bar_rule_id: threshold.bar_rule_id,
                                        name: (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).name: barre.name),
                                        type: (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).type: '')
                                    })
                                }
                            } else{
                                this.barres.push({
                                    id: barre.id,
                                    value: threshold.value,
                                    bar_rule_id: threshold.bar_rule_id,
                                    name:  (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).name: barre.name),
                                    type: (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).type: '')
                                })
                            }
                        }
                    }
                }
            }

            /*
            if (this.selectedConcourPhases?.deliberation?.bar_rules && this.selectedConcourPhases?.deliberation?.barres) {
                this.barres = this.selectedConcourPhases.deliberation.bar_rules
                    .filter((bar_rule: any) => bar_rule.type !== BarRuleType.BAR_RULE_TYPE_ABSENCE)
                    .map((bar_rule: any) => {
                        let _barre: any
                        this.selectedConcourPhases.deliberation.barres.some((barre: any) => {
                            return barre.thresholds.some((threshold: any) => {
                                if (threshold.bar_rule_id === bar_rule.id) {
                                    _barre = {
                                        barre_id: barre.id,
                                        value: threshold.value
                                    }
                                    return true
                                }
                            })
                        })

                        return {
                            barre_id: _barre.barre_id || 0,
                            id: bar_rule.id,
                            name: bar_rule.name.charAt(0).toUpperCase() + bar_rule.name.slice(1),
                            value: _barre.value || null
                        }
                    })
            }
            */

            for(let i = 0; i < this.barres; i++) {
                if(this.barres[i].type === 'point') {
                    this.noPrecisionNeed = false
                }
            }

            this.setValueOfStep(this.barres)
            this.ready = true
        }
    }

    getBarreRuleByIdOfConcour(id: any, concour: any) {
        for(const bar_rule of concour.deliberation.bar_rules){
            if(bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    cancelEnregistrer() {
        this.$emit('close')
    }

    confirmerEnregistrer() {
        this.ready = false

        /* TOAST enregistrement en cours */
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        /* fin toast */

        /*
        const data: any = {
            concourPhase_id: this.selectedConcourPhases.id,
            data: {
                bar_id: params.barre_id,
                bar_rule_id: params.bar_rule_id,
                value: Number(params.value)
            }
        }

        this.$store
            .dispatch('definitionDesBarres/updateConcourPhaseBarThresholdValue' , data)
            .then(async (response) => {
                await this.$store.dispatch('definitionBarresListeNotes/RESET_LISTE_NOTES')
                this.$store.commit('definitionBarresStatistiquesEpreuves/RESET_STATE')
                this.$store.commit('definitionBarresStatistiquesConcours/RESET_STATE')
                this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', response.data.data)

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                // Surcharge les params uniquement s'ils sont vides au départ
                if (this.$store.state.definitionBarresListeNotes.params_definition_barres === {}) {
                    const params = { page: 1, sort: 'candidat.name', direction: 'asc' }
                    this.$store.commit('definitionBarresListeNotes/SET_PARAMS', params)
                }
                const payload = {
                    concour_id: this.$props.concour.concour_id,
                    phase_id: this.$props.concour.phase_id,
                    params:  this.$store.state.definitionBarresListeNotes.params_definition_barres
                }
                this.$store.dispatch('definitionBarresListeNotes/getTableauListeDesNotes', payload)
                    .then(() => {
                        this.$emit('close')
                    })
            })
            .catch(() => {
                this.error = this.$store.getters['definitionDesBarres/error']
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.ready = true
            })
        */


        const params = {
            data: {
                bar_rules: this.selectedConcourPhases.deliberation.bar_rules,
                barres: JSON.parse(JSON.stringify(this.selectedConcourPhases.deliberation.barres))
            },
            concourphase: this.selectedConcourPhases.id,
            _method: 'PUT'
        }

        for (let i = 0; i < this.barres.length; i++) {
            params.data.barres
                .filter((barre: any) => barre.id === this.barres[i].id)[0]?.thresholds
                .some((threshold: any) => {
                    if (threshold.bar_rule_id === this.barres[i].bar_rule_id) {
                        threshold.value = this.barres[i].value
                        return true
                    }
                })
        }

        this.$store.dispatch('definitionDesBarres/putDefinitionDesBarres', params)
            .then(async (response) => {
                await this.$store.dispatch('definitionBarresListeNotesAdmission/RESET_LISTE_NOTES')
                this.$store.commit('definitionBarresStatistiquesEpreuves/RESET_STATE')
                this.$store.commit('definitionBarresStatistiquesConcours/RESET_STATE')
                this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', response.data.data)

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                // Surcharge les params uniquement s'ils sont vides au départ
                if (Object.keys(this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres).length === 0) {
                    const params = { page: 1, sort: 'candidat.name', direction: 'asc' }
                    this.$store.commit('definitionBarresListeNotesAdmission/SET_PARAMS', params)
                }

                const payload = {
                    concour_id: this.$props.concour.concour_id,
                    phase_id: this.$props.concour.phase_id,
                    params:  this.$store.state.definitionBarresListeNotesAdmission.params_definition_barres
                }
                this.$store.dispatch('definitionBarresListeNotesAdmission/getTableauListeDesNotes', payload)
                    .then(() => {
                        /*
                        this.$store.dispatch('concourPhase/getConcourPhaseCompteurs', { concourphase_id: this.$props.concour.id })
                            .then(() => {
                            */
                        this.$emit('close')
                        //})
                    })
            })
            .catch(() => {
                this.error = this.$store.getters['definitionDesBarres/error']
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.ready = true
            })
    }

    /** deduis la précision des steps en fonction des valeurs barres */
    setValueOfStep(barres: any) {
        let steps: number[] = []
        barres.forEach((barre: any) => {
            const value = (barre.value || 0) * 100

            switch (true) {
                case (value === 0):
                    steps.push(1)
                    break
                case (value % 100 === 0):
                    steps.push(1)
                    break
                case (value % 50 === 0):
                    steps.push(0.50)
                    break
                case (value % 25 === 0):
                    steps.push(0.25)
                    break
                case (value % 10 === 0):
                    steps.push(0.10)
                    break
                case (value % 1 === 0):
                    steps.push(0.01)
                    break
                default:
                    steps.push(0.01)
            }
        })

        steps = steps.filter((value, index, self) => self.indexOf(value) === index)
        if (steps.length === 1) {
            this.selectedStep = steps[0]
        } else {
            this.selectedStep = 0.01
        }
    }
}
