









































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { formatDateSinTime } from '@/utils/helpers'
import { getTypeEnsembleOrder, getTypeEnsembleSpec } from '@/types/Ensemble'
import Candidats from '@/views/Planification/Vues/Candidats.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { Ability } from '@/types/Ability'
import { EtatPlanificationAuto } from '@/types/Planification'

@Component({
    computed: {
        ...mapState('session', ['sessionSelect']),
        ...mapState('sessionuser', ['statsValidation', 'error_session_user']),
        ...mapState('serie', ['series']),
        ...mapState('banque', ['banques']),
        ...mapState('ensemble', ['ensembles']),
        ...mapGetters('ensemble', ['ensemblesByType', 'ensembleByBanques']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('planification', ['nb_resultats', 'error_planification', 'loading_planification', 'serie_selected', 'show_chevauchement', 'historique_en_cours'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Candidats,
        ErrorDisplay
    }
})

export default class PlanificationManuelle extends Vue {
    @Prop() edition_histo?: boolean
    @Prop() reload_cand?: boolean
    @Prop() reload_serie?: boolean
    @Prop() active_onglet?: boolean
    @Prop() open?: boolean
    @Prop() params_route?: any

    Ability                    = Ability
    getTypeEnsembleOrder       = getTypeEnsembleOrder
    serie_select_id: any       = null
    etat_planification: any    = null
    mesure_selected: any       = null
    chaine_concours            = ''
    params: any                = {}
    load_datas_scroll          = false
    active_chevauchement       = false
    load_interface             = false
    read_only                  = true
    ensembles_by_concours: any = {}
    ensembles_trier: any       = {}
    formatDateSinTime          = formatDateSinTime
    getTypeEnsembleSpec        = getTypeEnsembleSpec
    check_dispo_examinateur    = false
    select_filtres: any        = {
        key: 'full',
        value: null
    }

    /**
     * @description Vérifie si les disponibilités des examinateurs sont validées
     * @returns {void}
     */
    @Watch('statsValidation')
    freshControl(): void {
        if (this.$store.state.sessionuser.statsValidation) {
            this.check_dispo_examinateur =
                this.$store.state.sessionuser.statsValidation.disponibilites_nb_valides === this.$store.state.sessionuser.statsValidation.ensembles_affectations_affectes
        }
    }

    /**
     * @description Recharge les données du candidat
     * @returns {void}
     */
    @Watch('reload_cand')
    reloadCandidat(): void {
        if (this.$props.reload_cand) {
            if (this.$props.params_route && this.$props.params_route.onglet && !this.$store.state.planification.historique_en_cours) {
                if (this.$props.params_route.serie_id !== null) {
                    this.refreshFiltreEquipe({
                        type: Number(this.$props.params_route.type_ensemble_id),
                        id: Number(this.$props.params_route.ensemble_id)
                    })
                } else {
                    this.params = {}
                }
                this.loadDatesSeries(false)
                this.$store.commit('planification/SET_LOADING', true)
                this.loadCandidatPlanification()
            } else {
                this.$store.commit('planification/SET_LOADING', true)
                this.loadDatesSeries(false)
                this.loadCandidatPlanification()
            }
        }
    }

    /**
     * @description Recharge les données de la série
     * @returns {void}
     */
    @Watch('reload_serie')
    reloadSerie(): void {
        this.$store.commit('planification/SET_LOADING', true)
        if (this.$props.reload_serie) {
            if ((this.$props.params_route && this.$props.params_route.serie_id === null) || (!this.$props.params_route)) {
                this.loadInfoSerie()
            } else {
                if (this.$store.state.serie.series.length !== 0 && !this.$store.state.planification.historique_en_cours) {
                    const serie_temp = this.$store.state.serie.series
                        .find((s: any) => s.id === Number(this.$props.params_route.serie_id))
                    this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
                    this.$emit('serie_id', Number(this.$props.params_route.serie_id))
                    this.loadDatesSeries(false)
                }
            }
        } else {
            this.$store.commit('planification/SET_LOADING', false)
        }
    }

    /**
     * @description Rafraichit l'édition
     * @returns {void}
     */
    @Watch('edition_histo')
    refreshEdition(): void {
        this.read_only = !this.$props.edition_histo
    }

    /**
     * @description Charge les données de la série sélectionnée
     * @returns {void}
     */
    @Watch('serie_select_id')
    loadSerieSelectedStore(): void {
        if (this.$props.active_onglet) {
            this.select_filtres = {
                key: 'full',
                value: null
            }
            this.$store.commit('planification/SET_LOADING', true)
            if (this.serie_select_id !== null && this.serie_select_id !== undefined && !this.$store.state.planification.historique_en_cours) {
                this.$emit('serie_id', Number(this.serie_select_id))
                this.loadDatesSeries(false)
                this.loadCandidatPlanification()
            }
        }
    }

    /**
     * @description Met à jour la série sélectionnée
     * @returns {void}
     */
    @Watch('serie_selected')
    majSerieSelectId(): void {
        this.serie_select_id = this.$store.state.planification?.serie_selected?.id
    }

    /**
     * @description Charge la planification
     * @returns {void}
     */
    @Watch('etat_planification')
    @Watch('mesure_selected')
    loadPlanification(): void {
        this.$store.commit('planification/SET_LOADING', true)
        // Chargement de la planification avec les filtres sélectionnés
        this.loadCandidatPlanification()
    }

    /**
     * @description Charge la série sélectionnée
     * @returns {void}
     */
    @Watch('series')
    loadSerieSelected(): void {
        if (this.$store.state.serie.series.length !== 0 && this.serie_select_id === null) {
            this.serie_select_id = this.$store.state.serie.series[0].id
        }
    }

    /**
     * @description Charge la chaine de concours pour les équipes transverses
     * @returns {void}
     */
    @Watch('banques')
    loadChaineConcours(): void {
        this.chaine_concours = this.$store.getters['banque/banques']
            ?.map((banque: any) => banque.code)
            ?.join(', ') || ''
    }

    /**
     * @description Récupère la liste des concours d'un ensemble
     * @param {any} ensemble - Ensemble à traiter
     * @returns {string} - Liste des concours
     */
    getEnsembleChaineConcours(ensemble: any): string {
        if (ensemble?.concour?.name) {
            return ensemble.concour.name
        } else {
            if (ensemble.groupeEpreuve === undefined) {
                const ensembleTemp = this.$store.state.ensemble.ensembles
                    .find((e: any)  => e.id === parseInt(ensemble.id))

                if (ensembleTemp) {
                    ensemble = ensembleTemp
                }
            }

            if (ensemble?.groupeEpreuve?.epreuves?.length > 0) {
                const retour = []
                for (let i = 0; i < ensemble.groupeEpreuve.epreuves.length; i++) {
                    if (ensemble?.groupeEpreuve?.epreuves[i]?.epreuves?.length > 0) {
                        for (let j = 0; j < ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            retour.push(ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour.code)
                        }
                    } else {
                        retour.push(ensemble.groupeEpreuve.epreuves[i].concour.code)
                    }
                }
                return retour.join(', ') || ''
            }

            return this.chaine_concours
        }
    }

    /**
     * @description Met à jour les jours de la série
     * @returns {void}
     */
    @Watch('active_chevauchement')
    refreshJoursSeries(): void {
        this.$store.commit('planification/SET_SHOW_CHEVAUCHEMENT', this.active_chevauchement)
        this.loadDatesSeries(true)
    }

    /**
     * @description Affiche les jours de la série avec chevauchement
     * @returns {void}
     */
    @Watch('show_chevauchement')
    refreshJoursSeriesWithC(): void {
        if (this.$store.state.planification.show_chevauchement) {
            this.active_chevauchement = this.$store.state.planification.show_chevauchement
            this.loadDatesSeries(true)
        }
    }

    /**
     * @description Récupère les ensembles
     * @returns {void}
     */
    @Watch('ensembles')
    loadStats(): void {
        this.ensembles_trier = this.$store.getters['ensemble/ensemblesByType']
        this.ensembles_by_concours = this.$store.getters['ensemble/ensembleByBanques']
    }

    /**
     * @description Récupère les filtres
     * @returns {void}
     */
    @Watch('select_filtres')
    getFiltres(): void {
        this.params = {}

        switch (this.select_filtres.key) {
            case 'handi':
                Vue.set(this.params, 'handi', 1)
                break

            case 'ensemble_id':
                Vue.set(this.params, 'ensemble_id', Number(this.select_filtres.value))
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.type))
                break

            case 'type_ensemble':
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.value))
                break

            case 'type_ensemble_concour':
                Vue.set(this.params, 'type_ensemble', Number(this.select_filtres.type))
                Vue.set(this.params, 'banque_id', Number(this.select_filtres.concour))
                break

            case 'full':
                this.params = {}
                break
        }

        this.$store.commit('planification/SET_LOADING', true)

        // Chargement de la planification avec les filtres sélectionnés
        this.loadCandidatPlanification()
    }

    /**
     * @description Ouvre la page d'édition du candidat
     * @param {id: number; tab?: string} params - Paramètres d'ouverture
     * @returns {void}
     */
    openEditCandidat(params: {id: number; tab?: string}): void {
        this.$emit('editCandidat', params)
    }

    /**
     * @description Nettoie params de la route
     * @returns {void}
     */
    cleanParams(): void {
        if (this.$props.params_route.serie_id !== null) {
            const stateObj = { link: 'planification' }
            history.replaceState(stateObj, '', 'planification')
            this.$props.params_route.serie_id = null
            this.$props.params_route.banque_id = null
            this.$props.params_route.ensemble_id = null
            this.$props.params_route.type_ensemble_id = null
        }
    }

    /**
     * @description Charge les infos de la série sélectionnée
     * @returns {void}
     */
    loadInfoSerie(): void {
        this.$store.commit('planification/SET_LOADING', true)
        const serie_temp = this.$store.state.serie.series.find((s: any) => s.id === this.serie_select_id)
        this.$store.commit('planification/SET_SERIE_SELECTED', serie_temp)
        this.$emit('serie_id', Number(this.serie_select_id))
        this.loadDatesSeries(false)
        // Chargement de la planification avec les filtres sélectionnés
        this.loadCandidatPlanification()
    }

    /**
     * @description Charge les jours du semainier
     * @param {boolean} refreshLocal - Rafraichir les candidats
     * @returns {void}
     */
    loadDatesSeries(refreshLocal: boolean): void {
        this.$store.commit('planification/SET_ERROR', null)
        const chevauchement: any = this.$store.getters['planification/get_nb_jours_chevauchement']
        const jours_series = this.$store.getters['disponibilite/create_jours_serie_with_chevauchement'](this.$store.state.planification.serie_selected, chevauchement.jours_avant, chevauchement.jours_apres)
        this.$store.commit('planification/SET_JOURS_SERIES', jours_series)
        if (refreshLocal) {
            this.$store.commit('planification/SET_LISTE_CANDIDATS_PLANIFICATION', this.$store.state.planification.liste_candidats_brute)
        }
    }

    /**
     * @description Chargement de la planification des candidats avec serie_id obligatoire
     * Possibilité de rajouter : ensemble_id
     * @returns {void}
     */
    loadCandidatPlanification(): void {
        if (this.$props.open) {
            this.$store.commit('planification/SET_ERROR', null)

            if (this.serie_select_id !== null) {
                if (this.$props.params_route && this.$props.params_route.serie_id && !this.$store.state.planification.historique_en_cours) {
                    this.serie_select_id = Number(this.$props.params_route.serie_id)
                } else if (this.$store.state.planification.historique_en_cours) {
                    // this.serie_select_id = this.$store.state.planification.historique_en_cours.serie_id
                }
                Vue.set(this.params, 'serie_id', this.serie_select_id)
            }

            Vue.set(this.params, 'etat_planification', this.etat_planification)
            Vue.set(this.params, 'amenagement_temps', this.mesure_selected)
            Vue.set(this.params, 'sort', 'name')
            Vue.set(this.params, 'direction', 'asc')

            const dispatch: any = () => {
                this.$store.dispatch('planification/getCandidatsPlanification', this.params).then(() => {
                    if (this.$props.params_route && this.$props.params_route.serie_id !== null) {
                        this.cleanParams()
                    }
                })
            }

            if (this.$props.params_route && this.$props.params_route.serie_id) {
                if (this.params.ensemble_id) {
                    dispatch()
                }
            } else {
                dispatch()
            }
        }
    }

    /**
     * @description Charge les candidats des pages suivantes
     * @param {number} page - Numéro de la page
     * @returns {Promise<void>}
     */
    async getMoreCandidats(page: number): Promise<void> {
        this.load_datas_scroll = true
        Vue.set(this.params, 'page', page)
        await this.$store.dispatch('planification/getMoreCandidatsPlanification', this.params)
        this.load_datas_scroll = false
    }

    /**
     * @description Met à jour le filtre équipe si on clique sur un tag
     * @param {any} filtre - Filtre à appliquer
     * @returns {void}
     */
    refreshFiltreEquipe(filtre: any): void {
        this.select_filtres = {
            key: 'ensemble_id',
            type: filtre.type,
            value: filtre.id
        }
    }

    /**
     * @description Active l'édition de la planification manuelle
     * @returns {void}
     */
    activeEdition(): void {
        this.$store.commit('planification/SET_ERROR', null)
        this.$store.commit('planification/MAJ_STATE_PLANIFICATION', EtatPlanificationAuto.EN_COURS)
        this.$store.dispatch('planification/createHistoPlanif', { serie_id: this.serie_select_id })
            .then(() => {
                this.read_only = false
                this.$emit('active_edition', true)
            })
            .catch((error) => {
                console.log(error)
            })
    }
}
