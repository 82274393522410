










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { BarTypePass } from '@/types/Barre'
import _ from 'lodash'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class PopUpDefinirLesBarres extends Vue {
    @Prop() showPopup?: boolean
    @Prop() concour?: any

    show                        = false
    selectedConcourPhases: any  = []
    barres: any                 = []
    ready                       = false
    error                       = null
    isWaiting                   = false
    selectedStep: any           = 0.01
    editionLocked               = false

    /* Valeur des steps */
    definitionStep = [1, 0.5, 0.25, 0.1, 0.01]

    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    /**
     * @description Met à jour les valeurs des barres en fonction de la précision
     * @returns {void}
     */
    @Watch('selectedStep')
    onSelectedStepChange(): void {
        this.barres.forEach((barre: any) => {
            if (Number.isInteger(this.selectedStep)) {
                barre.value = parseInt(barre.value)
            } else {
                let x = +barre.value + (this.selectedStep / 2)
                x = x - (x % this.selectedStep)
                barre.value = parseFloat(x.toFixed(this.selectedStep.toString().split('.')[1].length))
            }
        })
    }

    /**
     * @description Trouve l'id d'une bar_rule dans un concours
     * @param {any} id - Id à rechercher
     * @param {any} concour - Concours
     * @returns {any} - bar_rule
     */
    getBarreRuleByIdOfConcour(id: any, concour: any): any {
        for(const bar_rule of concour.deliberation.bar_rules){
            if(bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    /**
     * @description Initialise les données utilisées pour la popup
     * @returns {void}
     */
    @Watch('showPopup')
    initPopup(): void {
        if (this.showPopup === true) {
            this.error = null
            this.selectedConcourPhases = this.$store.getters['definitionDesBarres/selectedConcour']
            this.editionLocked = !!this.$store.getters['session/sessionSelect']?.barres_update_locked_at
            this.barres = []

            if(this.selectedConcourPhases.deliberation && this.selectedConcourPhases.deliberation.barres){
                for(const barre of this.selectedConcourPhases.deliberation.barres) {
                    if(!this.hiddenBarres.includes(barre.type)) {
                        for(const threshold of barre.thresholds) {
                            if (this.barres.length !== 0) {
                                const check_barre = this.barres.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                                if (!check_barre) {
                                    this.barres.push({
                                        id: barre.id,
                                        value: threshold.value,
                                        bar_rule_id: threshold.bar_rule_id,
                                        name: (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).name: barre.name)
                                    })
                                }
                            } else{
                                this.barres.push({
                                    id: barre.id,
                                    value: threshold.value,
                                    bar_rule_id: threshold.bar_rule_id,
                                    name: (this.selectedConcourPhases.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcourPhases).name: barre.name)
                                })
                            }
                        }
                    }
                }
            }

            this.setValueOfStep(this.barres)
            this.ready = true
        }
    }

    /**
     * @description Ferme la popup
     * @returns {void}
     */
    cancelEnregistrer(): void {
        this.$emit('close')
    }

    /**
     * @description Enregistre les barres
     * @returns {void}
     */
    confirmerEnregistrer() {
        if (this.editionLocked) {
            const idError = 't_error_' + Math.random()
            const errorToaster = {
                id: idError,
                toaster: 'b-toaster-top-right',
                variant: 'danger',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast('Le responsable du concours a désactivé la possibilité de saisir les barres. Votre modification n\'a pas été prise en compte.', errorToaster)
            this.cancelEnregistrer();
            return
        }

        this.ready = false

        /* TOAST enregistrement en cours */
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        /* fin toast */

        const params = {
            data: {
                bar_rules: this.selectedConcourPhases.deliberation.bar_rules,
                barres: JSON.parse(JSON.stringify(this.selectedConcourPhases.deliberation.barres))
            },
            concourphase: this.selectedConcourPhases.id,
            _method: 'PUT'
        }

        for (let i = 0; i < this.barres.length; i++) {
            params.data.barres
                .filter((barre: any) => barre.id === this.barres[i].id)[0]?.thresholds
                .some((threshold: any) => {
                    if (threshold.bar_rule_id === this.barres[i].bar_rule_id) {
                        threshold.value = this.barres[i].value
                        return true
                    }
                })
        }

        // Tri des barres par type (= ordre spécifique à l'enregistrement)
        params.data.barres = _.orderBy(params.data.barres, ['type'], ['asc'])

        this.$store.dispatch('definitionDesBarres/putDefinitionDesBarres', params)
            .then(async () => {
                this.$emit('load')

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                // Surcharge les params uniquement s'ils sont vides au départ
                if (this.$store.state.definitionBarresListeNotes.params_definition_barres === {}) {
                    const params = { page: 1, sort: 'candidat.name', direction: 'asc' }
                    this.$store.commit('definitionBarresListeNotes/SET_PARAMS', params)
                }
                const payload = {
                    concour_id: this.$props.concour.concour_id,
                    phase_id: this.$props.concour.phase_id,
                    params:  this.$store.state.definitionBarresListeNotes.params_definition_barres
                }
                this.$store.dispatch('definitionBarresListeNotes/getTableauListeDesNotes', payload)
                    .then(() => {
                        this.$emit('close')
                    })
            })
            .catch(() => {
                this.error = this.$store.getters['definitionDesBarres/error']
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.ready = true
            })
    }

    /**
     * @description Déduit la précision des steps en fonction des valeurs barres
     * @param {any} barres - Barres
     * @returns {void}
     */
    setValueOfStep(barres: any): void {
        let steps: number[] = []
        barres.forEach((barre: any) => {
            const value = (barre.value || 0) * 100

            switch (true) {
                case (value === 0):
                    steps.push(1)
                    break
                case (value % 100 === 0):
                    steps.push(1)
                    break
                case (value % 50 === 0):
                    steps.push(0.50)
                    break
                case (value % 25 === 0):
                    steps.push(0.25)
                    break
                case (value % 10 === 0):
                    steps.push(0.10)
                    break
                case (value % 1 === 0):
                    steps.push(0.01)
                    break
                default:
                    steps.push(0.01)
            }
        })

        steps = steps.filter((value, index, self) => self.indexOf(value) === index)
        if (steps.length === 1) {
            this.selectedStep = steps[0]
        } else {
            this.selectedStep = 0.01
        }
    }
}
