





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import { CandidatStatut } from '@/types/Candidat'
import { BarTypePass } from '@/types/Barre'

@Component({
    computed: {
        ...mapGetters('auth', ['can', 'user_session_id']),
        ...mapGetters('definitionBarresListeNotes', ['tableauListeNotesTrie', 'meta', 'loading', 'error', 'lastPage', 'totalPage', 'totalRows']),
        ...mapState('definitionBarresListeNotes', ['params_definition_barres']),
        ...mapGetters('definitionDesBarres', ['selectedTab'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})

export default class ListeDesNotes extends Vue {
    @Prop() concour?: any

    Ability                  = Ability
    genericfields: any       = []
    barres: any              = []
    filtres: any             = []
    dataForTab: Array<any>   = []
    selectedConcour          = this.$props.concour
    showModalEditionCandidat = false
    selected_tab             = 'resultats'
    concourTemp: any         = null
    hiddenBarres             = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    /**
     * @description Watcher sur la variable selectedTab
     * @return {void}
     */
    @Watch('selectedTab')
    watchSelectedTab(): void {
        if (this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie']?.length === 0 && this.$store.getters['definitionDesBarres/selectedTab'] === 'listeDesNotes') {
            this.$store.dispatch('definitionBarresListeNotes/getMoreTableauListeDesNotes', {
                concour_id: this.$props.concour.concour_id,
                phase_id: this.$props.concour.phase_id,
                params: this.$store.state.definitionBarresListeNotes.params_definition_barres
            })
        }
        this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie'])
    }

    /**
     * @description Watcher sur la variable tableauListeNotesTrie
     * @return {void}
     */
    @Watch('tableauListeNotesTrie')
    watchTab(): void {
        if (!this.$store.state.definitionBarresListeNotes.loading && this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie'].length) {
            this.dataForTab = []
            this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie'])
        }
    }

    /**
     * @description Rafraichissement de l'interface
     * @return {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    /**
     * @description Chargement des concours
     * @return {Promise<boolean>}
     */
    loadConcoursIfNotExists(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.$store.getters['concour/banques'].length) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => resolve())
            } else {
                resolve()
            }
        })
    }

    /**
     * @description Création des lignes du tableau
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Chargement de plus de données
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore)  {
            this.dataForTab = []
        }

        if (poData) {
            let first = true
            for (const result of poData) {
                // premiere partie en dur
                const identite = result.candidat ? (result.candidat.name ? result.candidat.name : '-') + ' ' + (result.candidat.first_name ? result.candidat.first_name : '-') : '-'
                const customColumnsParam = this.$store.getters['auth/findParameter']('listeEpreuveCorrectionResultatsListeNotesColumns')?.value

                const line: any = [
                    { label: this.$store.getters['auth/can'](Ability.CAND_VIEW) ? 'Cliquez pour consulter la fiche du candidat' : '', text: result.candidat.code, item: result.candidat,    type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action', disabled: !this.$store.getters['auth/can'](Ability.CAND_VIEW) },
                    { label: this.$store.getters['auth/can'](Ability.CAND_VIEW) ? 'Cliquez pour consulter la fiche du candidat' : '', text: identite,             item: result.candidat,    type: 'actionText', typeAction: 'openModaleCandidat', class: 'text-info item_action', disabled: !this.$store.getters['auth/can'](Ability.CAND_VIEW) }
                ]

                if (customColumnsParam) {
                    customColumnsParam.forEach((column: any) => {
                        let item = result;
                        column.key.split('.').forEach((segment: any) => {
                            if (item !== undefined) {
                                item = item[segment]
                            } else {
                                item = undefined
                            }
                        })

                        line.push({ label: '', item: item, type: column.type, typeAction: null, class: column.class })
                    })
                }

                /* Creation des parties dynamiques */
                if (result && result.epreuves) {
                    for (const field of this.genericfields) {
                        if (field.epreuve_id !== null && field.placement === 'dynamique1') {
                            const filter = result.epreuves.filter((epreuve: { epreuve_id: null }) => epreuve.epreuve_id === field.epreuve_id)
                            if (first) {
                                first = false
                            }
                            let note = '-'
                            if (filter && filter[0]) {
                                if (filter[0].statut === CandidatStatut.ABSENT) {
                                    note = 'Abs.'
                                } else if (filter[0].statut === CandidatStatut.PRESENT) {
                                    note = filter[0].note_finale
                                } else {
                                    note = filter[0].statut
                                }
                            }

                            line.push({ label: '', item: note, type: 'text', typeAction: null, class: 'text-center' })
                        }
                    }
                }

                /* 3ème partie en dur */
                /* Bonification */
                if (this.$props.concour?.deliberation?.bonus?.length) {
                    const bonif = { label: '', item: result.bonus ? result.bonus : '-', type: 'text', typeAction: null, class: 'text-center' }
                    line.push(bonif)
                }

                /*
                    4ème part Barres dynamique placement: dynamique2
                    Je prends la variable barres avec le nom des barres, je parcours le tableau pour construire ma ligne de résultats
                */
                if (result.infos && this.barres) {
                    for (const barre of this.barres) {
                        const lineBarre = { label: '', item: result.bar_rule_results[barre.bar_rule_id] || '-', type: 'text', typeAction: null, class: 'text-center text_bold' }
                        line.push(lineBarre)
                    }
                } else if (!result.infos && this.barres) {
                    // gestion du cas de figure ou le candidat n'a pas d'infos
                    this.barres.forEach(() => {
                        const lineBarre =  { label: '', item: '-', type: 'text', typeAction: null, class: 'text-center text_bold' }
                        line.push(lineBarre)
                    })
                }

                /* 5ème part reçu valeur 0 ou 1 ou 2 === non traité oui ou non */
                const recu = { label: '',
                    item: result.ranking_group?.name || '-',
                    type: 'text',
                    typeAction: null,
                    class: 'text-center text_bold'
                }
                line.push(recu)

                /* 6ème part classement ou elimination */
                const classement = { label: '', item: result.recu === 1 && result.rang ? result.rang : result.infos ? this.getEliminationInfos(result.infos) : '-', type: 'text', typeAction: null, class: 'bg-gray-light text-center text_bold' }
                line.push(classement)

                /* Part 6 Classement ou élimination */
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * @description Création des filtres pour le tableau
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = []
        this.filtres.push({ libelle: 'code', defautOptionlibelle: 'Rechercher un', model: 'candidat.code', value: '', index: 'code', datas: '', loading: this.$store.state.definitionBarresListeNotes.loading, options: { type: 'form', fieldsKey: 'candidat.code', strict: true } })
        this.filtres.push({ libelle: 'Nom', defautOptionlibelle: 'Rechercher un',   model: 'candidat.name', value: '', index: 'name', datas: '', loading: this.$store.state.definitionBarresListeNotes.loading, options: { type: 'form', fieldsKey: 'candidat.name' } })

        for (const barre of this.barres) {
            if (barre && barre.name) {
                barre.code = barre.bar_rule_id
                this.filtres.push({
                    libelle: barre.name,
                    defautOptionlibelle: 'Rechercher un',
                    model: `bar_rule_results_${barre.bar_rule_id}`, // 'barre' + (i + 1),
                    value: '',
                    index: `bar_rule_results_${barre.bar_rule_id}`, // 'barre' + (i + 1),
                    datas: barre,
                    loading: this.$store.state.definitionBarresListeNotes.loading,
                    options: { type: 'between', fieldsKey: 'json:bar_rule_results:' + barre.bar_rule_id } })// barre.id + '_' + barre.bar_rule_id } })
            }
        }

        const options_admissible: any = this.$store.getters['rankingGroup/rankingGroupsConcourPhase']
            .map((rg: any) => ({ index: rg.id, name: rg.name }))

        this.filtres.push({ libelle: 'Statut', defautOptionlibelle: 'Rechercher un', model: 'ranking_group_id', value: '', index: 'name', datas: options_admissible, loading: false, options: { type: 'deroulant', fieldsKey: 'ranking_group_id' } })
    }

    /**
     * @description Gestion des événements du tableau
     * @param {any} paParams - Paramètres de l'événement
     * @return {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'openModaleCandidat':
                    await this.editCandidat(paParams[1])
                    break
            }
        }
    }

    /**
     * @description Création des colonnes dynamiques
     * @param {any} epreuvesDeliberation - Epreuves de la délibération
     * @return {any}
     */
    createDynamicFields(epreuvesDeliberation: any): any {
        /* Creation des deux premieres lignes, en dur */
        const fields: any = [
            { key: 'candidat.code', label: 'Code', sortable: true, class: '', type: 'text', epreuve_id: null },
            { key: 'candidat.name', label: 'Identité', sortable: true, class: '', type: 'text', epreuve_id: null }
        ]

        const customColumnsParam = this.$store.getters['auth/findParameter']('listeEpreuveCorrectionResultatsListeNotesColumns')?.value
        if (customColumnsParam) {
            for (const column of customColumnsParam) {
                fields.push(column)
            }
        }

        /* Creation des colonnes dynamiques */
        for (const epreuve of epreuvesDeliberation) {
            /* filtre des phases */
            if (this.$props.concour?.phase_id && epreuvesDeliberation && epreuve.phase_id === this.$props.concour?.phase_id) {
                const field = { key: 'dyn:epreuves:epreuve_id:' + epreuve.epreuve.id, label: epreuve.epreuve.name, sortable: true, class: 'text-center', type: 'text', epreuve_id: epreuve.epreuve.id, placement: 'dynamique1' }
                fields.push(field)
            }
        }

        /* Creation de bonification en dur */
        if (this.$props.concour?.deliberation?.bonus?.length) {
            fields.push({ key: 'bonus', label: 'Bonif.', sortable: true, class: '', type: 'text', epreuve_id: null })
        }

        /* Creation dynamique des points/barres */
        for (const barre of this.barres) {
            if (barre && barre.name) {
                const field = { key: 'json:bar_rule_results:' + barre.bar_rule_id , label: '' + barre.name.charAt(0).toUpperCase() + barre.name.slice(1), sortable: true, class: '', type: 'text', epreuve_id: null, placement: 'dynamique2' }
                fields.push(field)
            }
        }

        /* Creation de recu (admissible ou non) */
        fields.push({ key: 'ranking_group_id', label: 'Statut', sortable: true, class: '', type: 'text', epreuve_id: null })

        /* creation de classement ou élimination */
        fields.push({ key: 'rang', label: 'Classement', sortable: true, class: 'bg-gray-light text-center', type: 'text', epreuve_id: null }) // ou élimination

        return fields
    }

    /**
     * @description Initialisation des données candidat affichées dans le tableau ainsi que les compteurs
     * @param {any} poCandidats - Candidats
     * @return {void}
     */
    initDatas(poCandidats: any): void {
        if (poCandidats && poCandidats.data && poCandidats.data.data) {
            this.setDataForGenericTab(poCandidats.data.data)
        }
    }

    /**
     * @description Chargement des résultats
     * @param {any} params - Paramètres
     * @return {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (JSON.stringify(this.$store.state.definitionBarresListeNotes.params_definition_barres) !== JSON.stringify(params)) {
            this.$store.commit('definitionBarresListeNotes/SET_PARAMS', params)
            await this.$store.dispatch('definitionBarresListeNotes/getMoreTableauListeDesNotes', {
                concour_id: this.$props.concour.concour_id,
                phase_id: this.$props.concour.phase_id,
                params: this.$store.state.definitionBarresListeNotes.params_definition_barres
            })
            this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie'])
        }
    }

    /**
     * @description Applique les filtres
     * @param {any} params - Paramètres
     * @return {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (JSON.stringify(this.$store.state.definitionBarresListeNotes.params_definition_barres) !== JSON.stringify(params)) {
            this.$store.commit('definitionBarresListeNotes/SET_PARAMS', params)

            // Rassemble les filtres de barres dans un seul paramètre
            params['filter-bar_rule_results'] = []
            Object.entries(params).forEach(([key, value]: any) => {
                if (key.startsWith('filter-bar_rule_results_')) {
                    params['filter-bar_rule_results'].push(value)
                    delete params[key]
                }
            })
            if (params['filter-bar_rule_results'].length > 0) {
                params['filter-bar_rule_results'] = params['filter-bar_rule_results'].join(';')
            } else {
                delete params['filter-bar_rule_results']
            }

            this.setDataForGenericTab([])
            await this.$store.dispatch('definitionBarresListeNotes/getTableauListeDesNotes', {
                concour_id: this.$props.concour.concour_id,
                phase_id: this.$props.concour.phase_id,
                params: this.$store.state.definitionBarresListeNotes.params_definition_barres
            })
            this.setDataForGenericTab(this.$store.getters['definitionBarresListeNotes/tableauListeNotesTrie'])
        }
    }

    /**
     * @description Récupère les informations d'élimination
     * @param {any} infos - Informations
     * @return {string}
     */
    getEliminationInfos(infos: any): string {
        let infoAffichage = ' - '
        switch (true) {
            case infos.absenceEliminatoire:
                infoAffichage = 'Absent(e)'
                break

            case infos.noteEliminatoire:
                infoAffichage = 'Épreuve éliminatoire'
                break

            case infos.barreEliminatoire:
                if (infos.barresEliminatoires && infos.barresEliminatoires.length > 1) {
                    infoAffichage = 'Rejet deux barres'
                } else if (infos.barresEliminatoires && infos.barresEliminatoires.length === 1) {
                    for (let i = 0; i < this.concour.deliberation.barres.length; i++) {
                        if (this.concour.deliberation.barres[i].code === infos.barresEliminatoires[0]) {
                            infoAffichage = this.concour.deliberation.barres[i].nom
                        }
                    }
                }
                break
        }
        return infoAffichage
    }

    /**
     * @description Affichage de la popup de candidat
     * @param {any} item - Candidat
     * @return {Promise<void>}
     */
    async editCandidat(item: any): Promise<void> {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/RESET_EDITED_CANDIDAT')
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            await this.$store.dispatch('candidat/getCandidat', item)
            this.$store.commit('candidat/SET_CANDIDAT_FILIERE', {
                candidatId: item.id,
                nomFiliere: item.filiere
            })
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCandidat = true
        }
    }

    /**
     * @description Réinitialisation de la popup de candidat
     * @return {void}
     */
    reinitShowModalCandidat(): void {
        this.showModalEditionCandidat = false
    }

    /**
     * @description Récupère la barre par son id
     * @param {any} id - Identifiant
     * @param {any} bar_rules - Règles de barre
     * @return {any}
     */
    getBarreRuleByIdOfConcour(id: any, bar_rules: any): any {
        for (const bar_rule of bar_rules){
            if (bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }

    /**
     * @description Chargement des barres
     * @return {void}
     */
    loadBarres(): void {
        this.barres = []

        if (this.selectedConcour.deliberation &&  this.selectedConcour.deliberation.barres){
            for (const barre of this.selectedConcour.deliberation.barres) {
                if (!this.hiddenBarres.includes(barre.type)) {
                    for (const threshold of barre.thresholds) {
                        if (this.barres.length !== 0) {
                            const check_barre = this.barres.find((b: any) => String(b.bar_rule_id) === String(threshold.bar_rule_id)) //  String(b.id) === String(barre.id) &&
                            if (!check_barre) {
                                //this.barres_actives.push(threshold)
                                this.barres.push({
                                    id:barre.id,
                                    type: barre.type,
                                    ranking_group_id: barre.ranking_group_id,
                                    bar_rule_id: threshold.bar_rule_id,
                                    name: (this.selectedConcour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcour.deliberation.bar_rules).name :  barre.name)
                                })
                            }
                        } else {
                            this.barres.push({
                                id:barre.id,
                                type: barre.type,
                                ranking_group_id: barre.ranking_group_id,
                                bar_rule_id: threshold.bar_rule_id,
                                name:  (this.selectedConcour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, this.selectedConcour.deliberation.bar_rules).name : barre.name)
                            })
                        }
                    }
                }
            }
        }
    }

    /**
     * @description Chargement des données
     * @return {Promise<void>}
     */
    async load(): Promise<void> {
        // Si aucun concours n'a été chargé, alors on les récupère (nécessaire pour l'affichage des résultats sur le popup d'édition d'un candidat)
        await this.loadConcoursIfNotExists()
        const payload = { concour_id: this.selectedConcour.concour_id, phase_id: this.selectedConcour.phase_id }
        this.loadBarres()
        this.$store.dispatch('concour/getConcour', payload.concour_id)
            .then(async (response) => {
                await this.$store.dispatch('rankingGroup/getRankingGroupsConcourPhase', payload)

                if (!this.$store.state.definitionBarresListeNotes.params_definition_barres) {
                    const params = { page: 1, sort: 'rang', direction: 'asc' }
                    this.$store.commit('definitionBarresListeNotes/SET_PARAMS', params)
                }

                this.concourTemp = response.data.data
                this.loadBarres()
                this.genericfields = this.createDynamicFields(response.data.data.epreuves_deliberation)

                await this.$store.dispatch('definitionBarresListeNotes/getTableauListeDesNotes', {
                    ...payload,
                    params:  { page: 1, sort: 'rang', direction: 'asc' }
                })

                this.setFiltersForGenericTab()
            })
    }

    /**
     * @description Montage du composant
     * @return {void}
     */
    mounted(): void {
        this.load()
    }
}
