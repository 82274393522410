


































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import _ from 'lodash'
import { Code, RankingGroup } from '@/types/RankingGroup'
import { getFileNameFromHeader } from '@/utils/helpers'
import { BarTypePass } from '@/types/Barre'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('definitionDesBarres', [
            'tableauDesConcours',
            'meta',
            'links',
            'loading',
            'error',
            'currentPage',
            'lastPage',
            'totalPage',
            'totalRows'
        ]),
        phasesListed() {
            return [ ...new Set(this.$store.getters['definitionDesBarres/tableauDesConcours']
                .map((concour: any) => JSON.stringify({
                    id: concour.phase.id,
                    name: concour.phase.name
                })))
            ].map((phase: any) => JSON.parse(phase))
        },
        isCNG() {
            return this.$store.getters['auth/can']('codeClient')?.value === 'CNG'
        }
    },
    components: {
        ExaGenericTable,
        FontAwesomeIcon
    }
})
export default class DefinitionDesBarres extends Vue {
    Ability = Ability

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filterOn = []
    stickyHeader = true
    filtres: any = []
    dataForTab: Array<any> = []
    ranking_groups: Array<RankingGroup> = []
    phases: Array<any> = []
    showSelectPhaseModal = false
    phase_selected: any = {}
    params = null
    exportInProgress = false
    exportPVActivated = false
    export_type = ''
    modeAuto = true
    updateFilters = false

    genericfields = [
        { key: 'phase.id', label: 'Phase', sortable: true, class: '', type: 'text' },
        { key: 'concour.id', label: 'Concours', sortable: true, class: '', type: 'text' },
        { key: 'concour.banque.id', label: 'Filière', sortable: true, class: '', type: 'text' },
        {
            key: 'notes_finales_validated',
            label: 'Notes finales validées',
            sortable: true,
            class: 'text-center',
            type: 'text'
        },
        {
            key: 'barre_valide',
            label: 'Définition des barres',
            sortable: false,
            class: 'text-center',
            type: 'text'
        },
        {
            key: 'barre_principale',
            label: 'Barre principale',
            sortable: true,
            class: 'text-center',
            type: 'text'
        },
        {
            key: 'barre_secondaire',
            label: 'Barre secondaire',
            sortable: true,
            class: 'text-center',
            type: 'text'
        },
        { key: 'candidats', label: 'Candidats inscrits', sortable: true, class: 'text-center', type: 'text' },
        { key: 'candidats_recus', label: 'Non éliminés', sortable: true, class: 'text-center', type: 'text' },
        { key: 'link', label: '', sortable: false, class: '', type: 'text' }
    ]

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    barres_actives: Array<any> = []
    hiddenBarres = [
        BarTypePass.BAR_TYPE_ANONYMOUS
    ]

    getBarreRuleByIdOfConcour(id: any, bar_rules: any) {
        for (const bar_rule of bar_rules) {
            if (bar_rule.id === id) {
                return bar_rule
            }
        }
        return null
    }


    create_fields() {
        if (this.modeAuto) {
            this.genericfields = []
            this.barres_actives = []
            const tableauDesConcours = this.$store.getters['definitionDesBarres/tableauDesConcours']

            this.genericfields.push({ key: 'phase.id', label: 'Phase', sortable: true, class: '', type: 'text' })
            this.genericfields.push({ key: 'concour.id', label: 'Concours', sortable: true, class: '', type: 'text' })
            this.genericfields.push({ key: 'concour.banque.name', label: 'Filière', sortable: true, class: '', type: 'text' })
            this.genericfields.push({ key: 'notes_finales_validated', label: 'Notes finales validées', sortable: true, class: 'text-center', type: 'text' })
            this.genericfields.push({ key: 'barre_valide', label: 'Définition des barres', sortable: false, class: 'text-center', type: 'text' })

            for (const concour of tableauDesConcours) {
                if (concour.deliberation && concour.deliberation.barres) {
                    for (const barre of concour.deliberation.barres) {
                        if (!this.hiddenBarres.includes(barre.type)) {
                            for (const threshold of barre.thresholds) {
                                if (this.barres_actives.length !== 0) {
                                    const check_barre = this.barres_actives.find((b: any) => String(b.id) === String(barre.id) && String(b.bar_rule_id) === String(threshold.bar_rule_id))
                                    if (!check_barre) {
                                        //this.barres_actives.push(threshold)
                                        this.barres_actives.push({
                                            id: barre.id,
                                            type: barre.type,
                                            ranking_group_id: barre.ranking_group_id,
                                            bar_rule_id: threshold.bar_rule_id,
                                            name: (concour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, concour.deliberation.bar_rules).name : barre.name)
                                        })
                                    }
                                } else {
                                    this.barres_actives.push({
                                        id: barre.id,
                                        type: barre.type,
                                        ranking_group_id: barre.ranking_group_id,
                                        bar_rule_id: threshold.bar_rule_id,
                                        name: (concour.deliberation.bar_rules.length > 1 ? this.getBarreRuleByIdOfConcour(threshold.bar_rule_id, concour.deliberation.bar_rules).name : barre.name)
                                    })
                                }
                            }
                        }
                    }
                }
            }

            for (const barre of this.barres_actives) {
                this.genericfields.push({ key: 'barre_' + barre.id, label: barre.name, sortable: false, class: 'col-min-width text-center', type: 'text' })
            }

            this.genericfields.push({ key: 'candidats', label: 'Candidats inscrits', sortable: true, class: 'text-center', type: 'text' })
            this.genericfields.push({ key: 'candidats_recus', label: 'Non éliminés', sortable: true, class: 'text-center', type: 'text' })
            this.genericfields.push({ key: 'link', label: '', sortable: false, class: '', type: 'text' })
        } else {

            this.genericfields = [
                { key: 'phase.id', label: 'Phase', sortable: true, class: '', type: 'text' },
                { key: 'concour.id', label: 'Concours', sortable: true, class: '', type: 'text' },
                { key: 'concour.banque.name', label: 'Filière', sortable: true, class: '', type: 'text' },
                {
                    key: 'notes_finales_validated',
                    label: 'Notes finales validées',
                    sortable: true,
                    class: 'text-center',
                    type: 'text'
                },
                {
                    key: 'barre_valide',
                    label: 'Définition des barres',
                    sortable: true,
                    class: 'text-center',
                    type: 'text'
                },
                {
                    key: 'barre_principale',
                    label: 'Barre principale',
                    sortable: true,
                    class: 'text-center',
                    type: 'text'
                },
                {
                    key: 'barre_secondaire',
                    label: 'Barre secondaire',
                    sortable: true,
                    class: 'text-center',
                    type: 'text'
                },
                { key: 'candidats', label: 'Candidats inscrits', sortable: true, class: 'text-center', type: 'text' },
                { key: 'candidats_recus', label: 'Non éliminés', sortable: true, class: 'text-center', type: 'text' },
                { key: 'link', label: '', sortable: false, class: '', type: 'text' }
            ]
        }

        // Parcours des lignes pour récupérer les barres actives

    }

    // Création des lignes du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        this.create_fields()
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                let barre = []
                if (!this.modeAuto) {
                    barre = result?.deliberation?.barres
                    if (barre?.length) {
                        barre = barre.filter((barre: any) => {
                            return this.ranking_groups.find((rankingGroup: any) => {
                                return barre.ranking_group_id === rankingGroup.id
                            })
                        })[0]
                    }
                }

                let line: Array<any> = []

                const phase = result?.phase?.name || '-'
                line.push({ label: '', item: phase, type: 'text', typeAction: null, class: '' })

                const concour = result?.concour?.name || '-'
                line.push({ label: '', item: concour, type: 'text', typeAction: null, class: '' })

                const filiere = result?.concour?.banque?.name || '-'
                line.push({ label: '', item: filiere, type: 'text', typeAction: null, class: '' })

                const notesFinalesValidees = this.getPuceColorNotesFinales(result)
                line.push({ label: '', item: notesFinalesValidees, type: 'icons', typeAction: null, class: 'text-center' })

                if (!this.modeAuto) {
                    const definitionDesBarres = this.getPuceColorDefinitionDesBarres(result, barre)
                    line.push({ label: definitionDesBarres[0].title, item: definitionDesBarres, type: 'icons', typeAction: null, class: 'text-center' })

                    const barrePrincipale = barre?.thresholds && barre?.thresholds[0]?.value ? barre.thresholds[0].value : '-'
                    line.push({ label: '', item: barrePrincipale, type: 'text', typeAction: null, class: 'text-center' })

                    const barreSecondaire = barre?.thresholds && barre?.thresholds[1]?.value ? barre.thresholds[1].value : '-'
                    line.push({ label: '', item: barreSecondaire, type: 'text', typeAction: null, class: 'text-center' })
                } else {
                    const lineTemp = []

                    let nb_barre_en_cours = 0

                    for (const barreTemp of this.barres_actives) {
                        let check_barre: any = null

                        if (result.deliberation.barres) {
                            for (const barreDelib of result.deliberation.barres) {
                                for (const threshold of barreDelib.thresholds) {
                                    if (String(threshold.bar_rule_id) === String(barreTemp.bar_rule_id) && String(barreDelib.id) === String(barreTemp.id)) {
                                        if (threshold.value !== null) {
                                            nb_barre_en_cours += 1
                                        }
                                        check_barre = {
                                            barre_id: barreTemp.id,
                                            bar_rule_id: barreTemp.bar_rule_id,
                                            value: threshold.value
                                        }
                                    }
                                }
                            }
                        }
                        if (check_barre) {
                            check_barre.concour_phase_id = result.id
                        }
                        lineTemp.push({ label: '', item: check_barre?.value || '', type: 'text', typeAction: null, class: 'text-center' })
                    }

                    let class_affichage = 'text-tertiary'
                    let title_affichage = 'Barre(s) non définie(s)'
                    if (result.deliberation.barreValide) {
                        class_affichage = 'text-success'
                        title_affichage = 'Barre(s) validée(s)'
                    } else if (nb_barre_en_cours > 0) {
                        class_affichage = 'text-info'
                        title_affichage = 'Barre(s) en cours de définition'
                    }
                    const definitionDesBarres = [{ name: 'circle', class: class_affichage, title: title_affichage }]
                    line.push({ label: definitionDesBarres[0].title, item: definitionDesBarres, type: 'icons', typeAction: null, class: 'text-center' })
                    line = line.concat(lineTemp)
                }

                const candidatsInscrits = result?.candidats_count || '-'
                line.push({ label: '', item: candidatsInscrits, type: 'text', typeAction: null, class: 'text-center' })

                const candidatsRecus = result?.candidats_recus_count || '-'
                line.push({ label: '', item: candidatsRecus, type: 'text', typeAction: null, class: 'text-center' })

                const link = [
                    {
                        name: 'arrow-circle-right',
                        class: result.ajustements_validated_at ? 'text-info' : 'text-secondary',
                        id: result.id,
                        barre: result
                    }
                ]
                line.push({ label: 'Ouvrir', item: link, type: 'icons', typeAction: (result.ajustements_validated_at ? 'link' : ''), class: 'text-center' })

                this.dataForTab.push(line)
            }
        }
    }

    // Création des filtres
    setFiltersForGenericTab(filter_phase: any = null, filter_concours: any = null, filter_banque: any = null, filter_notes: any = null, filter_barres: any = null): void {
        let deroulant_banques = _.orderBy(
            this.$store.getters['banque/banques'].map((banque: any) => {
                return { index: banque.id, name: banque.name }
            }), 'name', 'asc')

        let deroulant_concours = _.orderBy(
            this.$store.getters['concour/concours'].map((concour: any) => {
                return { index: concour.id, name: concour.name }
            }), 'name', 'asc')

        const deroulant_phases = this.phases.map((phase: any) => {
            return { index: phase.id, name: phase.name }
        })

        const deroulant_bool = [
            { index: 1, name: 'Oui' },
            { index: 0, name: 'Non' }
        ]

        if (filter_concours && filter_concours !== 'undefined') {
            // Filtrer les filières
            deroulant_banques = _.orderBy(
                this.$store.getters['concour/concours']
                    .filter((concour: any) => {
                        return concour.id === parseInt(filter_concours)
                    })
                    .map((concour: any) => {
                        return { index: concour.banque.id, name: concour.banque.name }
                    }), 'name', 'asc')

        }
        if (filter_banque && filter_banque !== 'undefined') {
            // Filtrer les concours
            deroulant_concours = _.orderBy(
                this.$store.getters['concour/concours']
                    .filter((concour: any) => {
                        return concour.banque.id === parseInt(filter_banque)
                    })
                    .map((concour: any) => {
                        return { index: concour.id, name: concour.name }
                    }), 'name', 'asc')
        }

        this.filtres = [
            {
                libelle: 'Phase',
                defautOptionlibelle: 'Rechercher une',
                model: 'phase.id',
                value: isNaN(parseInt(filter_phase)) ? '' : parseInt(filter_phase),
                index: 'phase.id',
                datas: deroulant_phases,
                loading: this.$store.getters['definitionDesBarres/loading'],
                options: { type: 'deroulant', fieldsKey: 'phase.id', strict: true }
            },
            {
                libelle: 'Concours',
                defautOptionlibelle: 'Rechercher un',
                model: 'concour.id',
                value: isNaN(parseInt(filter_concours)) ? '' : parseInt(filter_concours),
                index: 'concour.id',
                datas: deroulant_concours,
                loading: this.$store.getters['definitionDesBarres/loading'],
                options: { type: 'deroulant', fieldsKey: 'concour.id', strict: true }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour.banque.id',
                value: isNaN(parseInt(filter_banque)) ? '' : parseInt(filter_banque),
                index: 'concour.banque.id',
                datas: deroulant_banques,
                loading: this.$store.getters['definitionDesBarres/loading'],
                options: { type: 'deroulant', fieldsKey: 'concour.banque.name', strict: true }
            },
            {
                libelle: 'Notes finale validées',
                defautOptionlibelle: 'Rechercher une',
                model: 'notes_finales_validated',
                value: isNaN(parseInt(filter_notes)) ? '' : parseInt(filter_notes),
                index: 'notes_finales_validated',
                datas: deroulant_bool,
                loading: this.$store.getters['definitionDesBarres/loading'],
                options: { type: 'deroulant', fieldsKey: 'notes_finales_validated', strict: true }
            },
            {
                libelle: 'Définition des barres',
                defautOptionlibelle: 'Rechercher une',
                model: 'barre_valide',
                value: filter_barres && filter_barres !== 'undefined' ? filter_barres : '',
                index: 'barre_valide',
                datas: deroulant_bool,
                loading: this.$store.getters['definitionDesBarres/loading'],
                options: { type: 'deroulant', fieldsKey: 'barre_valide', strict: true }
            }
        ]
    }

    // Events du tableau
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    Object.entries(paParams[1]).forEach(([key, value]) => {
                        if (value === 'undefined') {
                            delete paParams[1][key]
                        }
                    })

                    // Permet de mettre à jour les données des filtres sans écraser la recherche
                    if (this.updateFilters) {
                        this.filtreSortHandler(this.params)
                        this.updateFilters = false
                    }

                    if (JSON.stringify(this.params) !== JSON.stringify(paParams[1])) {
                        this.updateFilters = true
                        this.params = paParams[1]
                        this.setFiltersForGenericTab(
                            paParams[1]['filter-phase.id'],
                            paParams[1]['filter-concour.id'],
                            paParams[1]['filter-concour.banque.id'],
                            paParams[1]['filter-notes_finales_validated'],
                            paParams[1]['filter-barre_valide']
                        )
                    }
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'link':
                    this.$store.commit('definitionBarresStatistiquesEpreuves/RESET_STATE')
                    this.$store.commit('definitionBarresAnalysesHypotheses/RESET_STATE')
                    this.$store.commit('definitionBarresStatistiquesConcours/RESET_STATE')
                    this.$store.commit('definitionDesBarres/SET_SELECTED_CONCOUR', paParams[1][0].barre)

                    if (paParams[1][0].barre.phase.name === 'Admissibilité') {
                        await this.$store.dispatch('definitionBarresListeNotes/RESET_LISTE_NOTES')
                        await this.$router.push(`${this.$route.path}/${paParams[1][0].id}`)
                    } else if (paParams[1][0].barre.phase.name === 'Admission') {
                        await this.$store.dispatch('definitionBarresListeNotesAdmission/RESET_LISTE_NOTES')
                        await this.$router.push(`${this.$route.path === '/resultats' ? '/resultats_admission' : '/definition_des_barres_admission'}/${paParams[1][0].id}`)
                    }
                    break
            }
        }
    }

    // Chargement des résultats
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('definitionDesBarres/getMoreTableauDesConcours', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
                })
        }
    }

    // Applique les filtres
    filtreSortHandler(params: any): void {
        this.$store.dispatch('definitionDesBarres/getTableauDesConcours', params)
            .then(() => {
                this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
            })
    }

    // Creation des puces pour les notes finales validées
    getPuceColorNotesFinales(result: any): any[] {
        const class_affichage = result?.ajustements_validated_at ? 'text-success' : 'text-tertiary'
        return [{ name: 'circle', class: class_affichage }]
    }

    // Creation des puces pour definition des barres
    getPuceColorDefinitionDesBarres(result: any, barre: any): any[] {
        let class_affichage = 'text-tertiary'
        let title_affichage = 'Barre(s) non définie(s)'
        if (result?.deliberation?.barreValide) {
            class_affichage = 'text-success'
            title_affichage = 'Barre(s) validée(s)'
        } else if (barre?.thresholds && barre?.thresholds[0]?.value) {
            class_affichage = 'text-info'
            title_affichage = 'Barre(s) en cours de définition'
        }
        return [{ name: 'circle', class: class_affichage, title: title_affichage }]
    }

    requestExport(type: string): void {
        this.export_type = type
        if (this.phases.length === 1) {
            this.phase_selected = this.phases[0]

            if (this.export_type === 'pv') {
                this.exportPV()
            } else {
                this.exportExterne()
            }
        } else {
            this.showSelectPhaseModal = true
        }
    }

    startExport(): void {
        if (this.export_type === 'pv') {
            this.exportPV()
        } else {
            this.exportExterne()
        }
    }

    closeSelectPhaseModal(): void {
        this.showSelectPhaseModal = false
        this.phase_selected = null
        this.export_type = ''
    }

    exportPV(): void {
        if (this.exportInProgress) {
            return
        }
        this.exportInProgress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Génération du document en cours ...', infosToaster)

        this.$store.dispatch('concourPhase/exportConcoursPhasePV', { phase_id: this.phase_selected.id })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'export_pv.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.closeSelectPhaseModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportInProgress = false
            })
    }

    exportExterne(): void {
        if (this.exportInProgress) {
            return
        }
        this.exportInProgress = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Génération du document en cours ...', infosToaster)

        this.$store.dispatch('concourPhase/exportConcoursPhaseExterne', { phase_id: this.phase_selected.id })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'export.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.closeSelectPhaseModal()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportInProgress = false
            })
    }

    async load() {
        this.exportPVActivated = this.$store.getters['auth/findParameter']('exportPvDeliberation')?.value === '1'

        await this.$store.dispatch('concour/getConcours', { perPage: 0 })
        await this.$store.dispatch('banque/getBanques', { perPage: 0 })

        this.phases =
            [ ...new Set(this.$store.getters['concour/concours']
                .map((concour: any) => concour.phases)
                .flat(1)
                .map((phase: any) =>
                    JSON.stringify({
                        id: phase.phase_id,
                        name: phase.name
                    })
                ))
            ].map((phase: any) => JSON.parse(phase))

        if (!this.$store.getters['rankingGroup/rankingGroups'].length) {
            await this.$store.dispatch('rankingGroup/getRankingGroups')
        }
        this.ranking_groups = this.$store.getters['rankingGroup/rankingGroups']
            .map((rankingGroup: RankingGroup) => {
                return { id: rankingGroup.id, code: rankingGroup.code }
            })
            .filter((rankingGroup: RankingGroup) => {
                return rankingGroup.code === Code.CODE_ADMISSIBLE || rankingGroup.code === Code.CODE_ADMIS
            })

        this.$store.dispatch('definitionDesBarres/getTableauDesConcours').then(async () => {
            this.setFiltersForGenericTab()
            // this.create_fields()
            this.setDataForGenericTab(this.$store.getters['definitionDesBarres/tableauDesConcours'])
        })
    }

    mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
